<template>
  <ValidationObserver v-slot="{ errors, handleSubmit }">
    <item-edit-view
      class="post-editing"
      title="Статья"
      :subtitle="item ? item.title : null"
      :loading="loading"
    >
      <template v-slot:inner>
        <div v-if="loading">loading...</div>
        <v-flex
          v-if="item && item.title"
          align-center
          class="d-lg-flex post-editing__head text-body-1"
        >
          <div class="post-editing__head-main">
            <div style="word-break: break-word; letter-spacing: normal !important">
              <strong>{{ item.title }}</strong>
            </div>
          </div>
          <div class="post-editing__head-aside">
            <v-btn
              @click="copy"
              icon
              title="Скопировать"
            >
              <v-icon small>fa-copy</v-icon>
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn
              tag="a"
              :href="item && item.previewUrl"
              target="_blank"
              :disabled="!item"
              v-if="item && item.previewUrl"
              title="Превью"
              icon
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              right
              color="success"
              :loading="saving"
              @click.prevent="handleSubmit(save)"
            >
              <v-icon small>mdi-content-save</v-icon>
              Сохранить
            </v-btn>
          </div>
        </v-flex>
      </template>

      <v-card>
        <v-form v-if="!loading && item">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="info">Публикация</v-tab>
            <v-tab key="content">Контент</v-tab>
            <v-tab key="photo">Фото</v-tab>
            <v-tab key="relations">Связи</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item key="info">

                <v-row>
                  <v-col>
                    <v-select
                      v-model="item.status"
                      :items="statues"
                      label="Статус"
                    />
                  </v-col>
                  <v-col cols="3">
                    <date-time-picker label="Дата и время публикации" v-model="item.publishedAt" />
                  </v-col>
                </v-row>

                <validation-provider
                  v-slot="{ errors }"
                  name="Название"
                  rules="required"
                >
                  <v-text-field
                    v-model="item.title"
                    label="Заголовок"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Uri"
                  rules="required"
                >
                  <slug-editor
                    name="uri"
                    class="mb-12 mt-4"
                    :input="item.title"
                    label="URL-адрес статьи"
                    placeholder="URL-адрес статьи"
                    v-model="item.uri"
                    :error="errors[0]"
                    persistent-hint
                    hide-details="auto"
                  />
                </validation-provider>

                <v-textarea
                  v-model="item.excerpt"
                  label="Анонсирующий текст"
                ></v-textarea>

                <v-row>
                  <v-col>
                    <h3>Meta</h3>
                    <item-meta v-model="item.meta" />
                  </v-col>
                </v-row>


              </v-tab-item>

              <v-tab-item key="content">
                <div>
                  <v-input :value="item.contentSource">
                    <quill v-model="item.contentSource" :options="quillToolbar"/>
                  </v-input>
                </div>
              </v-tab-item>

              <v-tab-item key="photo">
                <v-row>
                  <v-col cols="12">
                    <v-img v-if="item.heroImage" :src="item.heroImage.url" width="50%" contain/>
                  </v-col>
                  <v-col cols="12">
                    <v-btn @click="choosePhoto" class="mr-1">{{ item.heroImage ? 'Изменить' : 'Выбрать' }}</v-btn>
                    <v-btn color="error" v-if="item.heroImage" @click="removePhoto">Удалить</v-btn>

                    <v-select
                      v-model="item.heroImageMode"
                      :items="heroImageModes"
                      label="Заходное фото"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="relations">
                <post-relations v-model="item" />
              </v-tab-item>

            </v-tabs-items>
          </v-card-text>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import { QUILL_EXTENDED } from '../../components/elements/quilljs/configs'
import blogService from '../../services/posts'
import PostRelations from './PostRelations.vue'
import SlugEditor from '../../components/Slug/SlugEditor.vue'
import { Notification } from 'element-ui'
import DateTimePicker from './DateTimePicker.vue'
import { ItemMeta } from '@/components'

export default {
  components: {
    DateTimePicker,
    PostRelations,
    SlugEditor,
    ItemMeta
  },
  mixins: [createOrUpdateViewMixin(blogService)],
  data () {
    return {
      quillToolbar: QUILL_EXTENDED,
      tab: null,
      statues: blogService.statusLabels,
      heroImageModes: blogService.heroImageModeLabels,
    }
  },
  methods: {
    choosePhoto () {
      this.openPhotoModal().$once('choose', (image) => {
        this.item.heroImage = image
      })
    },
    removePhoto () {
      this.item.heroImage = null
    },
    async copy () {
      const { data } = await blogService.copy(this.item.uuid)
      this.$router.push({ name: 'BlogPost', params: { id: data.uuid } })
      Notification({
        title: 'Скопировано!',
        message: 'Статья скопирована',
        type: 'success',
        duration: 3000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.post-editing__head {
  grid-gap: 20px;
}

.post-editing__head-main {
  flex: 1 1 100%;
}

.post-editing__head-aside {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}
</style>
